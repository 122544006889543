import configs from "@/../app.config.json"; 

let TITTLE = "";
switch (process.env.NODE_ENV) {
    case "development":
        TITTLE = configs.otimizacaoHomolog.titulo;
        break;
    case "preproduction":
        TITTLE = configs.otimizacaoPreProd.titulo;
        break;
    case "production":
    default:
        TITTLE = configs.otimizacaoProd.titulo;
        break;
}
let prefix = "/";

const linksAntigosApp = ["tickets/:idapresentacao"];
const linksOlds = [];

linksAntigosApp.forEach((item, index) => {
    linksOlds.push({
        path: prefix + item,
        name: "Update" + index,
        props: true,
        params: true,
        redirect: prefix + "update",
    });
});

// const isGamificationActive = () => {
//     return window.$root.$config.$features.GAMIFICATION === 'GAMIFICATION';
// };

// const isParqueActive = () => {
//     return window.$root.$config.$features.PARQUE === 'PARQUE';
// };

export default [
    {
        path: "*",
        name: "Error",
        component: () =>
            import(/* webpackChunkName: "error" */ "@/views/Error.vue"),
        meta: { title: TITTLE + "Error 404", requiresAuth: false },
    },
    {
        path: prefix + "politicas/privacidade",
        name: "Politics",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "login" */ "@/views/politicas/Politics.vue"
            ),
        meta: {
            title: TITTLE + "Política de Privacidade",
            requiresAuth: false,
        },
    },
    {
        path: prefix + "politicas/cookies",
        name: "PoliticsCookies",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "login" */ "@/views/politicas/Politics.vue"
            ),
        meta: {
            title: TITTLE + "Política de Privacidade",
            requiresAuth: false,
        },
    },
    //LOGIN/CADASTRO
    {
        path: prefix + "home",
        name: "Home",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "home" */ "@/views/Hub.vue"),
        meta: { title: TITTLE + "Home", requiresAuth: true },
    },
    {
        path: prefix + "/",
        name: "Login",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "login" */ "@/views/onboarding/Login.vue"
            ),
        meta: { title: TITTLE + "Login", requiresAuth: false },
    },
    {
        path: prefix + "olividelacontrasena",
        name: "Esquecisenha",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "esqueciSenha" */ "@/views/onboarding/Forgot-password.vue"
            ),
        meta: { title: TITTLE + "Olivdé la Contraseña", requiresAuth: false },
    },
    {
        path: prefix + "nuevacontrasena",
        name: "NovaSenha",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "NovaSenha" */ "@/views/Nova-senha.vue"
            ),
        meta: { title: TITTLE + "Nueva Contraseña", requiresAuth: false },
    },
    {
        path: prefix + "extranjero",
        name: "CadastroEstrangeiro",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "cadastro" */ "@/views/onboarding/Foreigner.vue"
            ),
        meta: { title: TITTLE + "Registro Extranjero", requiresAuth: false },
    },
    {
        path: prefix + "registro/:tipo",
        name: "Cadastro",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "cadastro" */ "@/views/onboarding/Signin.vue"
            ),
        meta: { title: TITTLE + "Registro", requiresAuth: false },
    },
    //PERFIL
    {
        path: prefix + "hub",
        name: "Hub",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "hub" */ "@/views/Hub.vue"),
        meta: { title: TITTLE + "Hub Torcedor", requiresAuth: true },
    },
    {
        path: prefix + "perfil",
        alias: [prefix + "hubperfil"],
        name: "Perfil",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "perfil" */ "@/views/Hub.vue"),
        meta: { title: TITTLE + "Hub Perfil", requiresAuth: true },
    },
    {
        path: prefix + "perfil/regalado",
        name: "HubDados",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/dados" */ "@/views/profile/Dados.vue"
            ),
        meta: { title: TITTLE + "Hub Regalado", requiresAuth: true },
    },
    {
        path: prefix + "perfil/direccion",
        name: "HubEndereco",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/endereco" */ "@/views/profile/Address.vue"
            ),
        meta: { title: TITTLE + "Hub Direccion", requiresAuth: true },
    },
    {
        path: prefix + "perfil/peticiones",
        name: "HubPedidos",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/address" */ "@/views/profile/Pedidos-abertos.vue"
            ),
        meta: { title: TITTLE + "Hub Peticiones", requiresAuth: true },
    },
    {
        path: prefix + "perfil/peticionesterminados",
        name: "HubPedidosFinalizados",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/address" */ "@/views/profile/Pedidos-finalizados.vue"
            ),
        meta: {
            title: TITTLE + "Hub Peticiones Terminados",
            requiresAuth: true,
        },
    },
    {
        path: prefix + "perfil/financiero",
        name: "HubFinanceiro",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/financeiro" */ "@/views/profile/Financeiro-prox.vue"
            ),
        meta: { title: TITTLE + "Hub Financiero", requiresAuth: true },
    },
    {
        path: prefix + "perfil/financieropagado",
        name: "HubFinanceiroPago",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/financeiropago" */ "@/views/profile/Financeiro-pago.vue"
            ),
        meta: { title: TITTLE + "Hub Financiero Pagado", requiresAuth: true },
    },
    {
        path: prefix + "perfil/financierotarjeta",
        name: "HubFinanceiroCartao",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/financeirocartao" */ "@/views/profile/Financeiro-cartao.vue"
            ),
        meta: { title: TITTLE + "Hub Financiero Tarjeta", requiresAuth: true },
    },
    //JOGOS
    {
        path: prefix + "campeonatos",
        name: "Campeonatos",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/jogos" */ "@/views/Championship.vue"
            ),
        meta: {
            title: TITTLE + "Campeonatos",
            requiresAuth: false,
            breadcrumb: [
                { name: "Inicio", link: "Hub" },
                { name: "Campeonatos" },
            ],
        },
    },
    {
        path: prefix + "campeonatos/:pagina",
        name: "PartidasCampeonatos",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/jogos" */ "@/views/Matchs-Championship.vue"
            ),
        meta: {
            title: TITTLE + "Partidos por campeonato",
            requiresAuth: false,
            breadcrumb: [
                { name: "Inicio", link: "Hub" },
                { name: "Campeonatos", link: "Campeonatos" },
                { name: "Partidos" },
            ],
        },
    },
    {
        path: prefix + "juegos",
        name: "Jogos",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "perfil/jogos" */ "@/views/Matchs.vue"),
        meta: {
            title: TITTLE + "Hub Juegos",
            requiresAuth: false,
            breadcrumb: [
                { name: "Inicio", link: "Hub" },
                { name: "Campeonatos", link: "Campeonatos" },
                { name: "Todos los juegos" },
            ],
        },
    },
    {
        path: prefix + "juegos/:campeonato/:data/:titulo",
        name: "JogosInfos",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "perfil/jogos" */ "@/views/Match.vue"),
        meta: { title: TITTLE + "Juegos", requiresAuth: false },
    },
    {
        path: prefix + "ultimos-juegos",
        name: "Jogosjafui",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "jogosjafui" */ "@/views/Past-matchs.vue"
            ),
        meta: { title: TITTLE + "Últimos Juegos", requiresAuth: false },
    },
    // {
    //     path: prefix + "juegos/pos-juego/:data/:titulo/:campeonato",
    //     name: "Posjogo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(/* webpackChunkName: "jogosjafui" */ "@/views/PosPlay.vue"),
    //     meta: { title: TITTLE + "Pós juego", requiresAuth: false },
    // },
    {
        path: prefix + "partidos-fuera-casa/:data/:titulo/:campeonato",
        name: "JogosFora",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "jogosfora" */ "@/views/Match-away.vue"
            ),
        meta: { title: TITTLE + "Partidos fuera de casa", requiresAuth: false },
    },
    //PLANOS
    {
        path: prefix + "planes",
        name: "Planos",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "plans" */ "@/views/Plans.vue"),
        meta: { title: TITTLE + "Planes", requiresAuth: true },
        children: [
            {
                path: "p/:plano",
                name: "PlanosSegmentado",
            },
        ],
    },
    {
        path: prefix + "planessilla",
        name: "PlanosCadeira",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "plans" */ "@/views/Planos.vue"),
        meta: { title: TITTLE + "Planes silla", requiresAuth: true },
    },
    {
        path: prefix + 'sermiembroacademia',
        name: 'SerSocioAcademia',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "sermiembroacademia" */ '@/views/checkout-member/Success-Academia.vue'),
        meta: { title: TITTLE + 'Ser Miembro Academia', requiresAuth: true }
    },
    {
        path: prefix + "serunadirecciondemiembro",
        name: "SerSocioEndereco",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "sersocioendereco" */ "@/views/checkout-member/Address.vue"
            ),
        meta: {
            title: TITTLE + "Ser una Direccion de Miembro",
            requiresAuth: true,
        },
    },
    {
        path: prefix + "seaunsociodepago",
        name: "SerSocioPagamento",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "sersociopagamento" */ "@/views/checkout-member/Payment.vue"
            ),
        meta: { title: TITTLE + "Sea un Socio Pagmento", requiresAuth: true },
    },
    {
        path: prefix + "seaunsocioactivación",
        name: "SerSocioAtivacao",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "sersocioativacao" */ "@/views/checkout-member/Activation.vue"
            ),
        meta: { title: TITTLE + "Sea un Socio Activación", requiresAuth: true },
    },
    {
        path: prefix + "seaunsociocheckout",
        name: "SerSocioCheckout",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "sersociocheckout" */ "@/views/checkout-member/Success.vue"
            ),
        meta: { title: TITTLE + "Sea un Socio Checkout", requiresAuth: true },
    },
    //TICKETS/CARRINHO
    {
        path: prefix + "tickets/:id",
        name: "PaginaTickets",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "tickets" */ "@/views/Match.vue"),
        meta: { title: TITTLE + "Tickets", requiresAuth: true },
    },

    {
        path: prefix + "amabilidad/:pagina",
        name: "Cart",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Carrinho" */ "@/views/cart/Cart.vue"),
        meta: { title: TITTLE + "Amabilidad", requiresAuth: true },
    },
    {
        path: prefix + "amabilidad/exito/:pagina",
        name: "CartSuccess",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "Carrinho - Sucesso" */ "@/views/cart/Success.vue"
            ),
        meta: { title: TITTLE + "Carrinho - Éxito", requiresAuth: true },
    },

    {
        path: prefix + "silladealquiler",
        name: "LocarCadeira",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "locarcadeira" */ "@/views/checkout-seats/Seat.vue"
            ),
        meta: { title: TITTLE + "Silla de Alquiler", requiresAuth: true },
    },
    {
        path: prefix + "alquilarsillainformacion",
        name: "LocarCadeiraInformacao",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "locarcadeirainformacao" */ "@/views/checkout-seats/Confirmation.vue"
            ),
        meta: {
            title: TITTLE + "Alquilar Silla Información",
            requiresAuth: true,
        },
    },
    {
        path: prefix + "alquilarsillapago",
        name: "LocarCadeiraPagamento",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "locarcadeirapagamento" */ "@/views/checkout-seats/Payment.vue"
            ),
        meta: { title: TITTLE + "Alquilar Silla Pago", requiresAuth: true },
    },
    {
        path: prefix + "locarcadeiraativacao",
        name: "LocarCadeiraAtivacao",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "locarcadeiraativacao" */ "@/views/Locacao-cadeira.vue"
            ),
        meta: {
            title: TITTLE + "Alquilar Silla Activación",
            requiresAuth: true,
        },
    },
    {
        path: prefix + "ubicacioncheckout",
        name: "PagarLocacaoSucesso",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "locacaocheckout" */ "@/views/checkout-seats/Success-seats.vue"
            ),
        meta: { title: TITTLE + "Ubicación Sucesso", requiresAuth: true },
    },
    //SERVICES/UTILS
    {
        path: prefix + "webmail/view/:idmail",
        name: "Webmail",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Webmail" */ "@/views/ViewEmails.vue"),
        meta: { title: TITTLE + "Webmail", requiresAuth: false },
    },
    {
        path: prefix + "en-vivo",
        name: "Live",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Carrinho" */ "@/views/Live.vue"),
        meta: { title: TITTLE + "En vivo", requiresAuth: true },
    },
    //SERVICOS
    {
        path: prefix + "servicios/ayuda",
        name: "Help",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Carrinho" */ "@/views/help/Help.vue"),
        meta: { title: TITTLE + "Ayuda", requiresAuth: true },
    },


    // {
    //     path: prefix + "servicios/ayuda/cas",
    //     name: "HelpCas",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Carrinho" */ "@/views/help/HelpCas.vue"
    //         ),
    //     meta: { title: TITTLE + "Ayuda", requiresAuth: true },
    // },
    // {
    //     path: prefix + "servcios/ayuda/sac",
    //     name: "HelpSac",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Carrinho" */ "@/views/help/HelpSac.vue"
    //         ),
    //     meta: { title: TITTLE + "Ajuda", requiresAuth: true },
    // },
    // {
    //     path: prefix + "servicios/ayuda/ouvidoria",
    //     name: "HelpOuvidoria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Carrinho" */ "@/views/help/HelpOmbudsman.vue"
    //         ),
    //     meta: { title: TITTLE + "Ayuda", requiresAuth: true },
    // },

    //ESPECIAIS
    {
        path: prefix + "design-system",
        name: "DesignSystem",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "DesignSystem" */ "@/views/DesignSystem.vue"
            ),
        meta: { title: TITTLE + "Design System", requiresAuth: true },
    },
    {
        path: prefix + "beneficios",
        name: "Benefits",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Webmail" */ "@/views/Benefits.vue"),
        meta: { title: TITTLE + "Beneficios", requiresAuth: true },
    }, 
    {
        path: prefix + "hubperfil/pedidos",
        name: "Update1",
        props: true,
        params: true,
        redirect: prefix + "update",
    },
    {
        path: prefix + "landpage/:pagina",
        name: "Landpage",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Webmail" */ "@/views/Landingpage.vue"),
        meta: { title: TITTLE + "Landpage", requiresAuth: true },
    },
    {
        path: prefix + "voucher",
        name: "Voucher",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Webmail" */ "@/views/Voucher.vue"),
        meta: { title: TITTLE + "Voucher", requiresAuth: true },
    },

    {
        path: prefix + "manejoFacial",
        name: "GestaoFacial",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "home" */ "@/views/Facial/gestaoFacial.vue"),
        meta: { title: TITTLE + "Manejo Facial", requiresAuth: true, breadcrumb: [
            { name: "Início", link: "Hub" },
            { name: "Gestão Facial" },
        ],},
    },

    // Institucionais
    // {
    //     path: prefix + "/",
    //     name: "HomeInstitucional",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(/* webpackChunkName: "BeiraRio" */ "@/views/Home.vue"),
    //     meta: { title: TITTLE + "HomeInstitucional", requiresAuth: false },
    // },

    // NOTICIAS
    // {
    //     path: prefix + "noticias",
    //     name: "Noticias",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/news/News.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Noticias",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Inicio", link: "Home" },
    //             { name: "Noticias" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "noticias/:categoria",
    //     name: "NoticiasCategoria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/news/NewsCategory.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "NoticiasCategoria",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Noticias", link: "Noticias" },
    //             { name: "Categoría" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "noticia/:category/:subtitle",
    //     name: "NoticiasSubtitulo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/news/NewsSubtitle.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Noticia-titulo",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Noticias", link: "Noticias" },
    //             { name: "Categoría", link: "NoticiasCategoria" },
    //             { name: "Noticia" },
    //         ],
    //     },
    // },

    // {
    //     path: prefix + "galeria",
    //     name: "Galeria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/galery/PhotoGalery.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Galería-fotos",
    //         requiresAuth: false,
    //         breadcrumb: [{ name: "Inicio", link: "Home" }, { name: "Galeria" }],
    //     },
    // },
    // {
    //     path: prefix + "galeria/:category",
    //     name: "GaleriaCategoria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/galery/PhotoCategory.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Galería-categoria",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Galería", link: "Galeria" },
    //             { name: "Categoría" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "galeria/:category/:subtitle",
    //     name: "GaleriaGrupo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/galery/PhotoGroup.vue"
    //         ),
    //     meta: { title: TITTLE + "Galería-grupo", requiresAuth: false },
    // },

    //PROJETOS
    // {
    //     path: prefix + "proyectos-colorados",
    //     name: "ProjetosColorados",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/projetos-colorados/Projects.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Proyectos Colorados",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Projetos Colorados" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "proyectos-colorados/:category",
    //     name: "ProjetosColoradosCategoria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/projetos-colorados/ProjectsCategory.vue"
    //         ),
    //     meta: { title: TITTLE + "Proyectos", requiresAuth: false },
    // },

    //FUTEBOL
    // {
    //     path: prefix + "futbol",
    //     name: "Futebol",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/football/FootBall.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Fútbol",
    //         requiresAuth: false,
    //         breadcrumb: [{ name: "Inicio", link: "Home" }, { name: "futbol" }],
    //     },
    // },
    // {
    //     path: prefix + "futbol/:category",
    //     name: "FutebolCategoria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/football/FootballCategory.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "futbol-categoría",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "futbol", link: "Futebol" },
    //             { name: "Categoría" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "futbol/:home/:equip/:category",
    //     name: "FutebolEquipe",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/football/FootballEquip.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "futbol-equipo",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Futbol", link: "Futebol" },
    //             {
    //                 name: "Categoría",
    //                 link: "FutebolCategoria",
    //                 FutebolCategoria: true,
    //             },
    //             { name: "Equipo" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "futbol/categoria/equipo/jugador/:player",
    //     name: "EquipPlayer",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/football/EquipPlayer.vue"
    //         ),
    //     meta: { title: TITTLE + "equipo-jugador", requiresAuth: false },
    // },

    //Clube
    // {
    //     path: prefix + "club",
    //     name: "Clube",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/club/Club.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "club",
    //         requiresAuth: false,
    //         breadcrumb: [{ name: "Inicio", link: "Home" }, { name: "Club" }],
    //     },
    // },
    // // Titulos
    // {
    //     path: prefix + "club/titulos",
    //     name: "HistoriaTitulos",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/titles/Titulos.vue"
    //         ),
    //     meta: { title: TITTLE + "Títulos", requiresAuth: false },
    // },
    // {
    //     path: prefix + "clube/titulos/:slug",
    //     name: "ClubTitle",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/titles/ClubTitle.vue"
    //         ),
    //     meta: { title: TITTLE + "Títulos", requiresAuth: false },
    // },
    // // História
    // {
    //     path: prefix + "club/historia",
    //     name: "Historia",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/club/HistoryClub.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "historia-clube",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Clube", link: "Club" },
    //             { name: "Historia-Comenzar" },
    //         ],
    //     },
    // },
    // // Idolos
    // {
    //     path: prefix + "club/idolos",
    //     name: "Idolos",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/club/Idols.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "idolos",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Club", link: "Clube" },
    //             { name: "Ídolos" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "club/idolos/:player",
    //     name: "IdolPlayer",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/club/Idol-Player.vue"
    //         ),
    //     meta: { title: TITTLE + "Jugador Idolo", requiresAuth: false },
    // },
    // // Simbolos
    // {
    //     path: prefix + "club/simbolos",
    //     name: "Simbolos",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Simbolos" */ "@/views/institucionais/simbols/Home.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Símbolos Colorados",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Club", link: "Clube" },
    //             { name: "Símbolos" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "simbolos/saci",
    //     name: "SimbolosSaci",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "SimbolosSaci" */ "@/views/institucionais/simbols/Saci.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Símbolos Colorados - Saci",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "simbolos/saci/:slug",
    //     name: "SaciSlide",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "SimbolosSaciNascimento" */ "@/views/institucionais/simbols/SaciSlide.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Símbolos Colorados - Saci",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "simbolos/distintivo",
    //     name: "SimbolosDistintivo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "SimbolosDistintivo" */ "@/views/institucionais/simbols/Distintivo.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Símbolos Colorados - Insignias",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "simbolos/distintivo/:slug",
    //     name: "SimbolosDistintivoSlide",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "SimbolosDistintivo1920" */ "@/views/institucionais/simbols/Distintivo-slide.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Símbolos Colorados - Insignias",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "simbolos/bandeira",
    //     name: "SimbolosBandeira",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "SimbolosBandeira" */ "@/views/institucionais/simbols/Bandeira.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Símbolos Colorados - Bandera",
    //         requiresAuth: false,
    //     },
    // },

    // {
    //     path: prefix + "club/lista-grenais",
    //     name: "ListaGrenais",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Grenais-List.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "lista-grenais",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Club", link: "Clube" },
    //             { name: "Lista de Grenais" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "club/himno-cifrar",
    //     name: "CifraHino",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Cifra-hino.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "hinno-cifrado",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Club", link: "Clube" },
    //             { name: "Cifrado de himnos" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "club/funciones-consulado",
    //     name: "FuncoesConsulado",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Funcoes-consulado.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "funciones-consulado",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Club", link: "Clube" },
    //             { name: "Funciones del Consulado" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "club/banderas-gigante",
    //     name: "FaixaGigante",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Faixa-gigante.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Banderas en Gigante",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Club", link: "Clube" },
    //             { name: "Banderas en Gigante" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "club /consulares-culturales",
    //     name: "ConsulesCulturais",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Consules-culturais.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "consulares-culturales",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Club", link: "Clube" },
    //             { name: "Cónsules Culturales" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "club/feci",
    //     name: "Feci",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Feci.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "feci",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Club", link: "Clube" },
    //             { name: "FECI" },
    //         ],
    //     },
    // },

    //   BEIRA-RIO
    // {
    //     path: prefix + "beira-rio",
    //     name: "BeiraRio",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/beira-rio/Beira-rio.vue"
    //         ),
    //     meta: { title: TITTLE + "Beira-Rio", requiresAuth: false },
    // },
    // {
    //     path: prefix + "beira-rio/:slug",
    //     name: "BeiraRioSlide",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/beira-rio/BeiraRioSlides.vue"
    //         ),
    //     meta: { title: TITTLE + "Beira-Rio", requiresAuth: false },
    // },

    // DIRETORIA
    // {
    //     path: prefix + "junta",
    //     name: "Directoria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Directoria" */ "@/views/institucionais/directors/BoardDirectors.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Directoria",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Directoria" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "junta/miembros",
    //     name: "DiretoriaMembros",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/directors/DirectorMembers.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Membros de la junta",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Directoria", link: "Diretoria" },
    //             { name: "Miembros" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "junta/consejo-fiscal",
    //     name: "ConselhoFiscal",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/directors/FiscalCouncil.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Consejo Fiscal",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Directoria", link: "Diretoria" },
    //             { name: "Consejo Fiscal" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "junta/consejo-administracion",
    //     name: "ConselhoGestao",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/directors/ManagementCouncil.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Consejo Administracion",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Directoria", link: "Diretoria" },
    //             { name: "Consejo Administracion" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "junta/consejo-deliberativo",
    //     name: "ConselhoDeliberativo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/directors/DeliberativeCouncil.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Consejo Deliberativo",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Directoria", link: "Diretoria" },
    //             { name: "Consejo Deliberativo" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "junta/ex-presidentes",
    //     name: "ExPresidentes",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/directors/ExPresidentes.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "junta-ex-presidentes",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Directoria", link: "Diretoria" },
    //             { name: "Ex-presidentes" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "junta/estatuto-club",
    //     name: "EstatutoClube",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/directors/StatuteClub.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Estatuto del Club",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Directoria", link: "Diretoria" },
    //             { name: "Estatuto del Club" },
    //         ],
    //     },
    // },
    
    //PARQUE GIGANTE
    // {
    //     path: prefix + "servicios",
    //     name: "Servicos",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/Servicos.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Servicios",
    //         requiresAuth: false,
    //     },
    // },
    //     path: prefix + "servicios/parquegigante",
    //     name: "ParqueGigante",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //           /* webpackChunkName: "ParqueGigante" */ "@/views/ParqueGigante/ParqueGigante.vue"
    //    ),
    //     meta: {
    //         title: TITTLE + "Parque Gigante",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Servicios", link: "Servicos" },
    //             { name: "Parque" },
    //         ],
    //         parque: true,
    //     },
    // },
    // {
    //     path: prefix + "servicios/parquegigante/:produto",
    //     name: "ProdutosParque",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "ParqueGigante" */ "@/views/ParqueGigante/Produtos-Parque.vue"
    //     ),
    //     meta: {
    //         title: TITTLE + "Producto Parque Gigante",
    //         requiresAuth: true,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Servicios", link: "Servicos" },
    //             { name: "Parque", link: "ParqueGigante" },
    //             { name: "Productos" },
    //         ],
    //         parque: true,
    //     },
    // },
    // {
    //     path: prefix + "servicios/parquegigante/productos/planes",
    //     name: "PlanosParque",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "ParqueGigante" */ "@/views/ParqueGigante/Plans-park.vue"
    //      ),
    //     meta: {
    //         title: TITTLE + "Planes Parque",
    //         requiresAuth: true,
    //     },
    // },



    // {
    //     path: prefix + "servicios/parquegigante/productos/reservar-parque",
    //     name: "ReservarPark",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "ParqueGigante" */ "@/views/ParqueGigante/Reservar-park.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "reservar",
    //         requiresAuth: true,
    //     },
    // },
    // {
    //     path: prefix + "servicios/parquegigante/productos/reservar-parque/confirmacion",
    //     name: "ReservarConcluir",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "ParqueGigante" */ "@/views/ParqueGigante/Reservar-pagamento.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "reservar-confirmacion",
    //         requiresAuth: false,
    //     },
    // },

    // MUSEU DO INTER
    // {
    //     path: prefix + "servicios/museo-inter",
    //     name: "MuseuDoInter",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "MuseuDoInter" */ "@/views/museu-do-inter/Home.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Museo Inter",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Servicios", link: "Servicos" },
    //             { name: "Museo Inter" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "servicios/visita-colorada",
    //     name: "VisitaColorada",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "MuseuDoInter" */ "@/views/museu-do-inter/Visita-Colorada.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Visita Colorada",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "servicios/visita-express",
    //     name: "VisitaExpress",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "MuseuDoInter" */ "@/views/museu-do-inter/Visita-Express.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Visita Express",
    //         requiresAuth: false,
    //     },
    // },

    // ESTACIONAMENTO
    // {
    //     path: prefix + "servicios/estacionamiento",
    //     name: "Estacionamento",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Estacionamento" */ "@/views/Estacionamento.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Estacionamiento",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Servicios", link: "Servicos" }, 
    //             { name: "Estacionamiento" },
    //         ],
    //     },
    // },

    // GAMIFICATION
    // {
    //     path: prefix + "experiencias",
    //     name: "Gamification",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "Diretoria" */ "@/views/gamification/Gamification.vue"
    //     ),
    //     meta: {
    //         title: TITTLE + "Experiencias",
    //         requiresAuth: true,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Hub" },
    //             { name: "Experiencia" },
    //         ],
    //         gamification: true,
    //     },
    // },
    // {
    //     path: prefix + "experiencias/catalogar",
    //     name: "Experiences",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "Diretoria" */ "@/views/gamification/Experiences.vue"
    //     ),
    //     meta: {
    //         title: TITTLE + "Experiencias - Catalogar de experiencias",
    //         requiresAuth: true,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Hub" },
    //             { name: "Experiencia", link: "Gamification" },
    //             { name: "Catalogar" },
    //         ],
    //         gamification: true,
    //     },
    // },
    // {
    //     path: prefix + "experiencias/gana-puntos",
    //     name: "EarnPoints",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "Diretoria" */ "@/views/gamification/Earn-points.vue"
    //     ),
    //     meta: {
    //         title: TITTLE + "Experiencias - Gana puntos",
    //         requiresAuth: true,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Hub" },
    //             { name: "Experiencia", link: "Gamification" },
    //             { name: "Gana puntos" },
    //         ],
    //         gamification: true,
    //     },
    // },
    // {
    //     path: prefix + "experiencias/extracto",
    //     name: "History",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "Diretoria" */ "@/views/gamification/History.vue"
    //     ),
    //     meta: {
    //         title: TITTLE + "Experiencias - Extracto",
    //         requiresAuth: true,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Hub" },
    //             { name: "Experiencia", link: "Gamification" },
    //             { name: "Histórico" },
    //         ],
    //         gamification: true,
    //     },
    // },
    // {
    //     path: prefix + "experiencias/rubros",
    //     name: "Rubros",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "Diretoria" */ "@/views/gamification/Rubros.vue"
    //    ),
    //     meta: {
    //         title: TITTLE + "Experiencias - Rubros",
    //         requiresAuth: true,
    //         breadcrumb: [
    //             { name: "Comenzar", link: "Home" },
    //             { name: "Experiencia", link: "Gamification" },
    //             { name: "Rubros" },
    //         ],
    //         gamification: true,
    //     },
    // },

    //ADMIN CADASTRO DE CONTEÚDOS
    {
        path: prefix + "we/CMS/registro/contenido",
        name: "adminRegister",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminView.vue"
            ),
        meta: {
            title: TITTLE + "Registro de contenido",
            requiresAuth: false,
        },
    },
    // {
    //     path: prefix + "we/CMS/registro/contenido/enVivo",
    //     name: "adminLive",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminLive.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Registro de video en vivo",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registro/contenido/transparencia",
    //     name: "adminTransparencia",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminTransparencia.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Portal da transparência",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registro/contenido/noticias",
    //     name: "adminNoticias",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminNews.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin de Noticias",
    //         requiresAuth: false,
    //     },
    // },
    {
        path: prefix + "we/CMS/registro/contenido/banners",
        name: "adminBanners",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminBanners.vue"
            ),
        meta: {
            title: TITTLE + "Admin de Banners",
            requiresAuth: false,
        },
    },
    // {
    //     path: prefix + "we/CMS/registro/contenido/galeria",
    //     name: "adminGaleria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminGallerys.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin de la galería",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registro/contenido/futbol",
    //     name: "adminFutebol",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminFootball.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin de fútbol",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registro/contenido/idolos",
    //     name: "adminIdolos",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminIdols.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin de Ídolos",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registro/contenido/projetos-colorados",
    //     name: "adminProjeto",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminProjetos.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin de Projetos Colorados",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registro/contenido/beirario",
    //     name: "adminBeiraRio",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminBeiraRio.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin do Beira Rio",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registro/contenido/distintivos",
    //     name: "adminDistintivo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminDistintivo.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin de Distintivos",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registro/contenido/bandeira",
    //     name: "adminBandeira",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminBandeira.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin da Bandeira",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registro/contenido/historia",
    //     name: "adminHistory",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminHistory.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin da História",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registro/contenido/saci",
    //     name: "adminSaci",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminSaci.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin do Saci",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registro/contenido/diretoria",
    //     name: "adminBoardDirectors",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminBoardDirectors.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin da Diretoria",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registro/contenido/mais-sobre-clube",
    //     name: "adminMoreAbout",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminMoreAbout.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin da Mais sobre o clube",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registro/contenido/lista-de-grenais",
    //     name: "adminGrenalList",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminGrenalList.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin lista de grenais",
    //         requiresAuth: false,
    //     },
    // },
    {
        path: prefix + "we/CMS/registro/contenido/servicio-de-juegos",
        name: "adminServicoJogo",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminServicoJogo.vue"
            ),
        meta: {
            title: TITTLE + "Admin Servicio de juego",
            requiresAuth: false,
        },
    },
    {
        path: prefix + "we/CMS/registro/contenido/rutas",
        name: "adminRotas",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "adminRotas" */ "@/views/admin/AdminRotas.vue"
            ),
        meta: {
            title: TITTLE + "Admin de Rutas",
            requiresAuth: false,
        },
    },
    // {
    //     path: prefix + "we/CMS/registro/contenido/parque",
    //     name: "adminParque",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "adminparque" */ "@/views/admin/AdminParque.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin del Parque",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registro/contenido/museo",
    //     name: "adminMuseu",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "adminMuseu" */ "@/views/admin/AdminMuseu.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin Museo",
    //         requiresAuth: false,
    //     },
    // },

    // PORTAL DA TRANSPARÊNCIA
    // {
    //     path: prefix + "portal-da-transparencia",
    //     name: "portalTransparencia",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Carrinho" */ "@/views/transparencia/Portal-transparencia-home.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Portal da Transparencia",
    //         requiresAuth: false,
    //     },
    // },
    // // TRANSPARENCIA
    // { 
    //     path:
    //         prefix +
    //         "portal-da-transparencia/informativo-financiero/:sub_menu",
    //     name: "TransparenciaSubMenu",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/transparencia/Transparencia.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Portal da Transparência",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "portal-da-transparencia/institucional/:sub_menu",
    //     name: "TransparenciaInstitucional",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/transparencia/Transparencia.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Portal da Transparência",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "portal-da-transparencia/programas-de-gestión/:sub_menu",
    //     name: "TransparenciaGestao",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/transparencia/Transparencia.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Portal da Transparência",
    //         requiresAuth: false,
    //     },
    // },

    {
        path: prefix + "detalhes-app",
        name: "DetalhesApp",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "BeiraRio" */ "@/views/App/Detalhes.vue"
            ),
        meta: {
            title: TITTLE + "Detalhes do App",
            requiresAuth: false,
        },
    },

    ...linksOlds,
];
