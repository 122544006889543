import Service from "@/controllers/Service.controller.js";
import GestaoSocio from '@/controllers/GestaoSocio.controller.js'
import StorageTable from "@/controllers/Storage.controller.js";

import Vue from "vue";

export default class Facial extends StorageTable {

    constructor() {
        super();
        this.$gestaoSocio = new GestaoSocio()
        this.contratos = []
        this.cartoes = []
        this.liberaFacial = false
    }

    async getVue() {
        if (this.$root) {
            return this.$root;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
        this.$root = document.querySelector("#app").__vue__.$root;
        return this.$root;
    }

    async getStatusFacial(){
        // FUNC: getStatusFacial()
        // CC: 
        const service = new Service()
        const session = this.getLocal('session')

        return await service.get("GETSTATUSFACIAL", '?idpessoa=' + session.idpessoa)
    }

    vinculaFacial(){
        // Cadastro Facial
        const service = new Service()
        // console.log(service.BASE_URL 
        //     + 'FacialRecognition?close_page=true&access_token=' + this.getLocal("token") 
        //     + '&url_redirect=' + 'https://socioahinter.eleventickets.com/pt/gestaoFacial')

        this.removeLocal("foto_sessao")

        window.location = service.BASE_URL 
            + 'FacialRecognition?'
            + '&url_redirect=' + encodeURIComponent(window.location.href)
            + '&access_token=' + this.getLocal("token")
        // window.open(service.BASE_URL 
        //     + 'FacialRecognition?'
        //     + '&url_redirect=' + encodeURIComponent(window.location.href)
        //     + '&access_token=' + this.getLocal("token"), '_blank')
    }
    

    async getConvidadosfacial(params) {    
        const service = new Service();
        return await service.post("GETCONVIDADOS", params);
    }

    async getIngressosContrato(params) {
        const dados = Buffer.from(JSON.stringify(params), 'utf-8').toString('base64');
    
        const service = new Service();
        return await service.get("GETINGRESSOSFACIAL", dados);
    }

    async setConvidado(params) {
    
        const service = new Service();
        return await service.post("SETCONVIDADO", params);
    }
    
    async deleteConvidado(id) {
    
        const service = new Service();
        return await service.delete("DELETECONVIDADO", id);
    }

    async getContratosFacial(){
        if (this.getLocal("contratos")) {
            window.$root.sociedades = this.getLocal("contratos");
        } else {
            await this.$gestaoSocio.gsContratosStatus()
            .then((contratosPessoa) => {
                if (contratosPessoa) {
                    window.$root.sociedades = contratosPessoa;
                    this.setLocal("contratos", contratosPessoa);
                } else {
                    window.$root.sociedades = [];
                }
            });
        }
        
        this.contratos = window.$root.sociedades;

        if (this.getLocal("cartoes_contratos")) {
            this.cartoes = this.getLocal("cartoes_contratos");
        } else {
            this.cartoes = await this.$gestaoSocio.gsCartoes({ status: 'ativo' })
            this.setLocal("cartoes_contratos", this.cartoes);
        }

        for (const cont of this.contratos) {

            this.cartoes.forEach((cart) => {
                if (
                    cont.idcontrato === cart.idcontrato &&
                    cart.idcartao_status == 105
                ) {
                    cont.cartao = cart.cartao;
                }
            });
        }

        return this.contratos
    }

    async vinculaConvidado(params){
        const service = new Service();

        return await service.post("VINCULACONVIDADO", params);
    }

    async validaPermicaoFacial(){
        
        let libera = false
        const root = await this.getVue();
        const session = window.$storage.getLocal("session")

        if(session.idpessoa_tipo == 100002){
            await this.getContratosFacial()

            this.contratos.forEach(contrato => {
                if(contrato?.libera_cadastro_facial == 1){
                    libera = true
                }
            });
        } else {
            libera = root.config.libera_facial_pg
        }

        this.liberaFacial = libera
    }

    async getPeriodos(){
        const service = new Service();

        let res = await service.get("GETFACIALCONFIGS");
        return !res.error ? res : false;
    }

}
Vue.prototype.$facial = new Facial();
window.$facial = new Facial();