<template>
    <v-app>
        <Manutencao v-if="$root.manutencao" />
        <Fila v-else-if="$root.fila > 0" />
        <router-view v-else />
        <Modal />
        <ModalSnackbar />
    </v-app>
</template>
<script>
import "@/plugins/filtros";
// TODO: CONTROLLERS
import "@/controllers/Carrinho.controller.js";
import "@/controllers/Config.controller";
import "@/controllers/Cookies.controller";
import "@/controllers/Facebook.controller";
import "@/controllers/GestaoSocio.controller";
import "@/controllers/Google.controller";
import "@/controllers/Sac.controller";
import "@/controllers/Service.controller.js";
import "@/controllers/Storage.controller.js";
import "@/controllers/SuperIngresso.controller";
import "@/controllers/Usuario.controller.js";
import "@/controllers/Facial.controller.js";


// TODO: PLUGINS
import "@/plugins/languagen";

// TODO: REGRA DE NEGOCIO
import { globalConfigs } from "@/../app.config.json";
import "@/controllers/RegradeNegocio.controller";

export default {
    components: {
        Modal: () => import("./components/Modal.vue"),
        ModalSnackbar: () => import("./components/ModalSnackbar.vue"),
        Loading: () => import("./ds/components/Loading.vue"),
        Manutencao: () => import("@/views/Manutencao.vue"),
        Fila: () => import("@/views/Fila.vue"),
    },

    data() {
        return {
            lang: "pt-BR",
            storage: window.$storage,
            config: globalConfigs,
        };
    },

    watch: {
        "$route.name"(e) {
            if (e == "Hub") {
                document.body.classList.add("bg-black");
            } else {
                document.body.classList.remove("bg-black");
            }

            this.$google.paginasView();
        },
    },

    async created() {
        this.lang = this.$lang_.get();
        this.$util.customApp(globalConfigs);
        this.$usuario.rotasEmails();
    },

    mounted() {
        if (
            !window.$storage.getLocal("token") &&
            window.$storage.getLocal("session") == true
        ) {
            window.$storage.removeLocal("token");
            window.$storage.removeLocal("session");
        }

        if (
            window.$storage.getLocal("session") &&
            window.$storage.getLocal("session") == null
        ) {
            window.$storage.destroyLocal(true);
        }
        if (this.$route.params["queueid"]) {
            window.$storage.setLocal("queueid", $root.$route.params["queueid"]);
        }
    },
};
</script>
<style>
@import url("./style.css");
@import url("./plugins/animater/animate.min.css");
@import url("./assets/css/vue-phone-number-input.css");
::selection {
    background-color: var(--dl-color-primary);
    color: var(--dl-color-white);
}

html {
    scroll-behavior: smooth;
}

.v-slide-group__next,
.v-slide-group__prev {
    min-width: 10px !important;
}
.animate-cookies-button {
    z-index: 99 !important;
    left: 85px !important;
    margin: 15px;
}
.v-text-field--filled.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control
    > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control
    > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control
    > .v-input__slot {
    min-height: 35px !important;
}
.v-text-field--outlined fieldset {
    top: -10px !important;
}
.v-expansion-panel-header {
    min-height: 30px !important;
}
.v-application .primary--text {
    color: var(--dl-color-black) !important;
    caret-color: var(--dl-color-black) !important;
}
@media (max-width: 991px) {
    .animate-cookies-button {
        bottom: var(--positionCookies) !important;
        left: 0 !important;
        margin: 0 15px;
    }
}
</style>
