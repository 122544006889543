import "@/controllers/Storage.controller.js";
import { mask } from "vue-the-mask";
export default {
    name: "EnderecoMixeds",
    components: {
        Label: () => import("@/ds/components/Label.vue"),
    },
    directives: { mask },
    data() {
        return {
            enderecoShow: false,
            editar_: false,
            lista: [],
            cadastro: {
                end_bairro: "",
                end_cep: "",
                end_cidade: "",
                end_complemento: "",
                end_estado: "",
                end_logradouro: "",
                end_numero: "",
                end_pais: "",
                preferencial: 0,
            },
            session: [],
            favorito: null,
            paises: [{ dsc: this.$t_("card_endereco_pais") }],
            estados: { estados: [{ dsc: this.$t_("card_endereco_estado"), iso: null }], pais: false },
            cidades: { cidades: [{ dsc: this.$t_("card_endereco_cidade") }], estado: false },
        };
    },
    watch: {
        "cadastro.end_cep"(cep) {
            if (cep.length == 9) {
                this.$util.getCEP(cep).then(async (response) => {
                    
                    if (response.data.erro ) {
                        this.$util.snackbar(this.$t_("input_cep"), "warning");
                        return
                    }
                    
                    this.cadastro.end_pais = "Brasil";

                    let dados = response.data;

                    if (this.cadastro.cep != cep) {
                        this.cadastro.cep = cep
                        this.cadastro.end_bairro = dados.bairro && dados.bairro != this.cadastro.end_bairro ? dados.bairro : this.cadastro.end_bairro
                        this.cadastro.end_cidade = dados.localidade && dados.localidade != this.cadastro.end_cidade ? dados.localidade : this.cadastro.end_cidade
                        this.cadastro.end_logradouro = dados.logradouro && dados.logradouro != this.cadastro.end_logradouro ? dados.logradouro : this.cadastro.end_logradouro
                        this.cadastro.end_estado = dados.uf && dados.uf != this.cadastro.end_estado ? dados.uf : this.cadastro.end_estado
                        this.cadastro.end_complemento = dados.complemento && dados.complemento != this.cadastro.end_complemento ? dados.complemento : this.cadastro.end_complemento
                    }
                    if (this.cadastro.end_pais) {
                        await this.getEstados(this.cadastro.end_pais);
                        if (this.cadastro.end_estado) {
                            setTimeout(async () => {
                                await this.getCidades(this.cadastro.end_estado);
                            }, 500);
                        }
                    }
                });
            }
        },
        'estados'(estado) { 
            setTimeout(async () => { 
                if(estado == '[object Object]' && this.cidades[0]){
                    this.getCidades(this.cadastro.end_estado);
                }
            }, 1000);  
        }
    },
    methods: {
        onSuccess() { },
        async getList() {
            this.$root.loadingSkeleton = true;

            return await this.$gestaoSocio.getEnderecosEntrega().then(async (response) => {
                if (response) {
                    let existeFavorito = false;
                    response.forEach(async (item, index) => {
                        if (item.preferencial) {
                            this.favorito = index;
                            existeFavorito = true;
                        }
                    });
                    if (response.length == 0) {
                        return []
                    } else if (!existeFavorito) {
                        this.lista = response;
                        return this.favoritar(response[0]);
                    } else {
                        this.lista = response;
                        return response
                    }
                }
            });
        },
        favoritar(endereco) {
            this.$root.loadingSkeleton = true;
            if (endereco) {
                this.$gestaoSocio.setPreferencialEntrega({
                    idpessoa: endereco.idpessoa,
                    idendereco: endereco.idendereco,
                })
                    .then(async response => {
                        endereco.preferencial = true
                        if (this.session) {
                            let cadastro = Object.assign(this.session, endereco)
                            await this.$usuario.validarCadastro(cadastro, "editarEndereco")
                            setTimeout(() => {
                                this.$router.go();
                            }, 3000);
                        } else {
                            setTimeout(() => {
                                this.$router.go();
                            }, 3000);
                        }
                    });
            }
        },
        editar(cadastro) {
            this.$emit("updateEditar", cadastro);
        },
        async remover(endereco, listaEndereco) {
            this.$root.loadingSkeleton = true
            await this.$gestaoSocio.removeEndereco({
                idpessoa: endereco.idpessoa,
                idendereco: endereco.idendereco
            }).then(async () => {
                if (listaEndereco.length >= 2) {
                    await this.getList();
                } else {
                    let cadastro = Object.assign(this.session, this.cadastro)
                    await this.$usuario.validarCadastro(cadastro, "editarEndereco")
                    setTimeout(() => {
                        this.$router.go();
                    }, 3000);
                }
            });
        },
        cadastrarNovo(listaEndereco = []) {

            this.$root.loading = true;
            const cadastro = Object.assign({}, this.cadastro);

            if (!cadastro.end_cep || cadastro.end_cep.length < 1 || cadastro.end_cep == '') {
                this.$root.loading = false
                this.$util.snackbar(this.$t_('perfil_preencha_cep'), 'error');
                this.$util.scroll('form-end_cep')
                return
            }

            if (!cadastro.end_logradouro || cadastro.end_logradouro.length < 2 || cadastro.end_logradouro == '') {
                this.$root.loading = false
                this.$util.snackbar(this.$t_('perfil_preencha_end_corret'), 'error');
                this.$util.scroll('form-end_logradouro')
                return
            }

            if (!cadastro.end_numero || cadastro.end_numero.length < 1 || cadastro.end_numero == '') {
                this.$root.loading = false
                this.$util.snackbar(this.$t_('perfil_preencha_numero_corret'), 'error');
                this.$util.scroll('form-end_numero')
                return
            }

            if (!cadastro.end_bairro || cadastro.end_bairro.length < 2 || cadastro.end_bairro == '') {
                this.$root.loading = false
                this.$util.snackbar(this.$t_('perfil_preencha_bairro_corret'), 'error');
                this.$util.scroll('form-end_bairro')
                return
            }

            if (!cadastro.end_cidade || cadastro.end_cidade.length < 2 || cadastro.end_cidade == '') {
                this.$root.loading = false
                this.$util.snackbar(this.$t_('perfil_preencha_cidade_corret'), 'error');
                this.$util.scroll('form-end_cidade')
                return
            }

            if (!cadastro.end_estado || cadastro.end_estado.length < 1 || cadastro.end_estado == '') {
                this.$root.loading = false
                this.$util.snackbar(this.$t_('perfil_preencha_estado_corret'), 'error');
                this.$util.scroll('form-end_estado')
                return
            }

            if (!cadastro.end_pais || cadastro.end_pais.length < 2 || cadastro.end_pais == '') {
                this.$root.loading = false
                this.$util.snackbar(this.$t_('perfil_preencha_pais_corret'), 'error');
                this.$util.scroll('form-end_pais')
                return
            }

            if (listaEndereco.length == 0) {
                cadastro.preferencial = true;
            }

            this.$gestaoSocio.setEnderecoEntrega(cadastro).then(async () => {
                if (cadastro.preferencial) {
                    await this.$usuario.validarCadastro(
                        Object.assign(this.session, cadastro),
                        "editarEndereco"
                    );
                }

                this.$emit("updateEndereco", true);
                this.$root.loadingSkeleton = true;

                await this.getList().then(() => {
                    setTimeout(() => {
                        if (this.lista.length >= 1) {
                            this.$root.loading = false;
                            this.$router.go();
                        }
                    }, 3000);
                });
            });
        },
        async getEstados(e) {
            if (e != this.estados.pais) {
                this.cidades = [{ dsc: this.$t_('card_endereco_cidade') }]
                for (let i = 0; i < this.paises.length; i++) {
                    if (this.paises[i].dsc == e) {
                        await this.$util.getEstados(this.paises[i].id).then(response => {
                            if (response.data && response.data.result.length > 0) {
                                this.estados = { estados: response.data.result, pais: e }
                            }
                        });
                    }
                }
            }
        },
        async getCidades(e) {
            if (e != this.cidades.estado) {
                for (let i = 0; i < this.estados.estados.length; i++) {
                    if (this.estados.estados[i].iso_full == e) {
                        await this.$util.getCidades(this.estados.estados[i].id).then(response => {
                            if (response.data && response.data.result.length > 0) {
                                this.cidades = { cidades: response.data.result, estado: e }

                            }
                        });
                    }
                }
            }
        },
    },
    mounted() {
        // VERIFICA SE TEM SESSÃO E MONTA COM OS DADOS OS INPUTS
        this.session = window.$storage.getLocal("session");
        this.session.cep = this.session.end_cep;

        if (!this.session) {
            this.$util.go("sair");
            return;
        }

        const mask = 'lang' in localStorage && localStorage.getItem('en-US') && this.session.dt_nascimento.indexOf('/') != -1 ? "MM/DD/YYYY" : this.session.dt_nascimento.indexOf('-') != -1 ? "YYYY-MM-DD" : "DD/MM/YYYY"

        //PEGA A DATA DE NASCIMENTO EM pt-BR E CONVERTE EM en-US
        this.session.editar = true
        var convertDatePt = this.$moment(this.session.dt_nascimento, mask).format("YYYY-MM-DD");
        this.session.dt_nascimento = convertDatePt;
        this.session.termos = true;
    }
}