import Vue from 'vue'
import App from '@/App.vue'
import router from '@/plugins/router/index.js'
import vuetify from '@/plugins/vuetify'
import { i18n } from '@/plugins/languagen'

// TODO: FILTROS

Vue.config.productionTip = false

const app = new Vue({
    render: h => h(App),
    vuetify,
    router,
    i18n,
    data(){
        return {
            search: '',
            cupom: '',
            manutencao: false,
            validarEmail: true,
            fila: 0,
            requisicoes: 0,
            config: [],
            session: [],
            responsive: 0,
            isSocio: false,
            isSocioConsulado: false,
            disponivelConsul: [],
            sociedades: [],
            loading: false,
            loadingSkeleton: false,
            loadingAdd: false,
            menumobile: false,
            idSYS: '',
            modal: {
                show: false,
                persistent: true,
                component: false,
                botao: '',
                botao1: '',
                link: '',
                link1: '',
                titulo: '',
                descricao: ''
            },
            modalSnack: {
                show: false,
                botao: '',
                link: '',
                titulo: '',
                descricao: '',
                tipo: false,
            },
            modalLogin: {
                show: false,
                persistent: false,
                logo: '',
                id: '',
                cor: ''
            },
            modalUsuario: {
                show: false,
                persistent: false,
                logo: '',
                id_homolog: '9e5103eb2637ff56d680ef97afc48348aa626ae4',
                id_prod: '9e5103eb2637ff56d680ef97afc48348aa626ae4',
                cor: ''
            },
            modalCadastro: {
                show: false,
                persistent: false
            },
            modalSenha: {
                show: false,
                persistent: false
            },
            modalTrocaSenha: {
                show: false,
                persistent: false
            },
            modalRG: {
                show: false,
                persistent: false,
            },
            openDialogPlanos: false,
            openAdicionarCartao: false,
            atualizaContaCorrente: false,
            carregarAssentos: false,
            atualizaLugares: false,
            celmask: false,
            playedRadio: false,
            cartoes: false,
            validaRefis: false,
            features: {}
        }
    },
    watch: {
        'modal.show'(e){
            if(!e){
                this.modal.descricao = ''
                this.modal.titulo = ''
                this.modal.component = false
            }
        },
        'modal.descricao'(e){
            if(e && e.length > 0){
                this.modal.show = true
            }
        }
    },
}).$mount('#app')

window.$root = app

