import configs from "@/../app.config.json";

let TITTLE = "";
switch (process.env.NODE_ENV) {
    case "development":
        TITTLE = configs.otimizacaoHomolog.titulo;
        break;
    case "preproduction":
        TITTLE = configs.otimizacaoPreProd.titulo;
        break;
    case "production":
    default:
        TITTLE = configs.otimizacaoProd.titulo;
        break;
}
let prefix = "/";

const linksAntigosApp = ["tickets/:idapresentacao"];
const linksOlds = [];

linksAntigosApp.forEach((item, index) => {
    linksOlds.push({
        path: prefix + item,
        name: "Update" + index,
        props: true,
        params: true,
        redirect: prefix + "update",
    });
});

// const isGamificationActive = () => {
//     return window.$root.$config.$features.GAMIFICATION === 'GAMIFICATION';
// };
// const isParqueActive = () => {
//     return window.$root.$config.$features.PARQUE === 'PARQUE';
// };

export default [
    {
        path: "*",
        name: "Error",
        component: () =>
            import(/* webpackChunkName: "error" */ "@/views/Error.vue"),
        meta: { title: TITTLE + "Error 404", requiresAuth: false },
    },
    {
        path: prefix + "politics/privacity",
        name: "Politics",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "login" */ "@/views/politicas/Politics.vue"
            ),
        meta: {
            title: TITTLE + "Privacy politics",
            requiresAuth: false,
        },
    },
    {
        path: prefix + "politics/cookies",
        name: "PoliticsCookies",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "login" */ "@/views/politicas/Politics.vue"
            ),
        meta: {
            title: TITTLE + "Privacy Policy",
            requiresAuth: false,
        },
    },
    //LOGIN/CADASTRO
    {
        path: prefix + "home",
        name: "Home",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "home" */ "@/views/Hub.vue"),
        meta: { title: TITTLE + "Home", requiresAuth: true },
    },
    {
        path: prefix + "/",
        name: "Login",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "login" */ "@/views/onboarding/Login.vue"
            ),
        meta: { title: TITTLE + "Login", requiresAuth: false },
    },
    {
        path: prefix + "forgotpassword",
        name: "Esquecisenha",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "esqueciSenha" */ "@/views/onboarding/Forgot-password.vue"
            ),
        meta: { title: TITTLE + "Forgot Password", requiresAuth: false },
    },
    {
        path: prefix + "newpassword",
        name: "NovaSenha",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "esqueciSenha" */ "@/views/Nova-senha.vue"
            ),
        meta: { title: TITTLE + "New Password", requiresAuth: false },
    },
    {
        path: prefix + "foreigner",
        name: "CadastroEstrangeiro",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "cadastro" */ "@/views/onboarding/Foreigner.vue"
            ),
        meta: { title: TITTLE + "Registration Foreigner", requiresAuth: false },
    },
    {
        path: prefix + "registration/:type",
        name: "Cadastro",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "cadastro" */ "@/views/onboarding/Signin.vue"
            ),
        meta: { title: TITTLE + "Registration", requiresAuth: false },
    },

    //PERFIL
    {
        path: prefix + "fanhub",
        name: "Hub",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "hub" */ "@/views/Hub.vue"),
        meta: { title: TITTLE + "Fan Hub", requiresAuth: true },
    },
    {
        path: prefix + "perfil",
        alias: [prefix + "hubprofile"],
        name: "Perfil",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "perfil" */ "@/views/Hub.vue"),
        meta: { title: TITTLE + "Hub Profile", requiresAuth: true },
    },
    {
        path: prefix + "hubprofile/data",
        name: "HubDados",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/dados" */ "@/views/profile/Dados.vue"
            ),
        meta: { title: TITTLE + "Hub Data", requiresAuth: true },
    },
    {
        path: prefix + "hubprofile/address",
        name: "HubEndereco",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/endereco" */ "@/views/profile/Address.vue"
            ),
        meta: { title: TITTLE + "Hub Address", requiresAuth: true },
    },
    {
        path: prefix + "hubprofile/resquest",
        name: "HubPedidos",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/address" */ "@/views/profile/Pedidos-abertos.vue"
            ),
        meta: { title: TITTLE + "Hub Resquests", requiresAuth: true },
    },
    {
        path: prefix + "perfil/finishedresquest",
        name: "HubPedidosFinalizados",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/address" */ "@/views/profile/Pedidos-finalizados.vue"
            ),
        meta: { title: TITTLE + "Hub Finished Resquests", requiresAuth: true },
    },
    {
        path: prefix + "hubprofile/financial",
        name: "HubFinanceiro",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/financeiro" */ "@/views/profile/Financeiro-prox.vue"
            ),
        meta: { title: TITTLE + "Hub Financial", requiresAuth: true },
    },
    {
        path: prefix + "perfil/financialpaid",
        name: "HubFinanceiroPago",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/financeiropago" */ "@/views/profile/Financeiro-pago.vue"
            ),
        meta: { title: TITTLE + "Hub Financial Paid", requiresAuth: true },
    },
    {
        path: prefix + "perfil/financialcard",
        name: "HubFinanceiroCartao",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/financeirocartao" */ "@/views/profile/Financeiro-cartao.vue"
            ),
        meta: { title: TITTLE + "Hub Financial Card", requiresAuth: true },
    },
    //JOGOS
    {
        path: prefix + "championship",
        name: "Campeonatos",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/jogos" */ "@/views/Championship.vue"
            ),
        meta: {
            title: TITTLE + "Championship",
            requiresAuth: false,
            breadcrumb: [
                { name: "Home", link: "Hub" },
                { name: "Championship" },
            ],
        },
    },
    {
        path: prefix + "championship/:pagina",
        name: "PartidasCampeonatos",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/jogos" */ "@/views/Matchs-Championship.vue"
            ),
        meta: {
            title: TITTLE + "Matches by championship",
            requiresAuth: false,
            breadcrumb: [
                { name: "Home", link: "Hub" },
                { name: "Championship", link: "Campeonatos" },
                { name: "Matchs" },
            ],
        },
    },
    {
        path: prefix + "games",
        name: "Jogos",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "perfil/jogos" */ "@/views/Matchs.vue"),
        meta: {
            title: TITTLE + "Hub Games",
            requiresAuth: false,
            breadcrumb: [
                { name: "Home", link: "Hub" },
                { name: "Championship", link: "Campeonatos" },
                { name: "All Gamess" },
            ],
        },
    },
    {
        path: prefix + "games/:campeonato/:data/:titulo",
        name: "JogosInfos",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "perfil/jogos" */ "@/views/Match.vue"),
        meta: { title: TITTLE + "Tickets", requiresAuth: false },
    },
    {
        path: prefix + "last-games",
        name: "Jogosjafui",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "jogosjafui" */ "@/views/Past-matchs.vue"
            ),
        meta: { title: TITTLE + "Last Games", requiresAuth: true },
    },
    // {
    //     path: prefix + "games/post-game/:data/:titulo/:campeonato",
    //     name: "Posjogo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(/* webpackChunkName: "jogosjafui" */ "@/views/PosPlay.vue"),
    //     meta: { title: TITTLE + "Post game", requiresAuth: false },
    // },
    {
        path: prefix + "games-away/:data/:titulo/:campeonato",
        name: "JogosFora",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "jogosfora" */ "@/views/Match-away.vue"
            ),
        meta: { title: TITTLE + "Games Away", requiresAuth: false },
    },
    //PLANOS
    {
        path: prefix + "plans",
        name: "Planos",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "plans" */ "@/views/Plans.vue"),
        meta: { title: TITTLE + "plans", requiresAuth: true },
        children: [
            {
                path: "p/:plano",
                name: "PlanosSegmentado",
            },
        ],
    },
    {
        path: prefix + "planeschair",
        name: "PlanosCadeira",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "plans" */ "@/views/Planos.vue"),
        meta: { title: TITTLE + "plans chair", requiresAuth: true },
    },

    {
        path: prefix + "parthneracademia",
        name: "SerSocioAcademia",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "parthneracademia" */ "@/views/checkout-member/Success-Academia.vue"
            ),
        meta: { title: TITTLE + "Parthner Academia", requiresAuth: true },
    },
    {
        path: prefix + "parthneradress",
        name: "SerSocioEndereco",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "sersocioendereco" */ "@/views/checkout-member/Address.vue"
            ),
        meta: { title: TITTLE + "Parthner Adress", requiresAuth: true },
    },
    {
        path: prefix + "parthnerpayment",
        name: "SerSocioPagamento",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "sersociopagamento" */ "@/views/checkout-member/Payment.vue"
            ),
        meta: { title: TITTLE + "Parthner Payment", requiresAuth: true },
    },
    {
        path: prefix + "parthneractivation",
        name: "SerSocioAtivacao",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "sersocioativacao" */ "@/views/checkout-member/Activation.vue"
            ),
        meta: { title: TITTLE + "Parthner Activation", requiresAuth: true },
    },
    {
        path: prefix + "parthnercheckout",
        name: "SerSocioCheckout",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "sersociocheckout" */ "@/views/checkout-member/Success.vue"
            ),
        meta: { title: TITTLE + "Parthner Checkout", requiresAuth: true },
    },
    //TICKETS/CARRINHO
    {
        path: prefix + "tickets/:id",
        name: "PaginaTickets",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "tickets" */ "@/views/Match.vue"),
        meta: { title: TITTLE + "Tickets", requiresAuth: true },
    },

    {
        path: prefix + "cart/:pagina",
        name: "Cart",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Carrinho" */ "@/views/cart/Cart.vue"),
        meta: { title: TITTLE + "Carrinho", requiresAuth: true },
    },
    {
        path: prefix + "cart/success/:pagina",
        name: "CartSuccess",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "Carrinho - Sucesso" */ "@/views/cart/Success.vue"
            ),
        meta: { title: TITTLE + "Cart - Success", requiresAuth: true },
    },

    {
        path: prefix + "chairrent",
        name: "LocarCadeira",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "locarcadeira" */ "@/views/checkout-seats/Seat.vue"
            ),
        meta: { title: TITTLE + "Rent Chair", requiresAuth: true },
    },
    {
        path: prefix + "rentchairinformation",
        name: "LocarCadeiraInformacao",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "locarcadeirainformacao" */ "@/views/checkout-seats/Confirmation.vue"
            ),
        meta: { title: TITTLE + "Rent Chair Information", requiresAuth: true },
    },
    {
        path: prefix + "rentchairpayment",
        name: "LocarCadeiraPagamento",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "locarcadeirapagamento" */ "@/views/checkout-seats/Payment.vue"
            ),
        meta: { title: TITTLE + "Rent Chair Payment", requiresAuth: true },
    },
    {
        path: prefix + "rentchairactivation",
        name: "LocarCadeiraAtivacao",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "locarcadeiraativacao" */ "@/views/Locacao-cadeira.vue"
            ),
        meta: { title: TITTLE + "Rent Chair Activation", requiresAuth: true },
    },
    {
        path: prefix + "rentsuccess",
        name: "PagarLocacaoSucesso",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "rentcheckout" */ "@/views/checkout-seats/Success-seats.vue"
            ),
        meta: { title: TITTLE + "Rent Success", requiresAuth: true },
    },
    //SERVICES/UTILS
    {
        path: prefix + "webmail/view/:idmail",
        name: "Webmail",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Webmail" */ "@/views/ViewEmails.vue"),
        meta: { title: TITTLE + "Webmail", requiresAuth: false },
    },
    {
        path: prefix + "live",
        name: "Live",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Carrinho" */ "@/views/Live.vue"),
        meta: { title: TITTLE + "Ao vivo", requiresAuth: true },
    },
    //SERVICOS

    {
        path: prefix + "services/help",
        name: "Help",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Carrinho" */ "@/views/help/Help.vue"),
        meta: { title: TITTLE + "Help", requiresAuth: false },
    },
    // {
    //     path: prefix + "services/help/cas",
    //     name: "HelpCas",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Carrinho" */ "@/views/help/HelpCas.vue"
    //         ),
    //     meta: { title: TITTLE + "Help", requiresAuth: true },
    // },
    // {
    //     path: prefix + "services/help/sac",
    //     name: "HelpSac",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Carrinho" */ "@/views/help/HelpSac.vue"
    //         ),
    //     meta: { title: TITTLE + "Help", requiresAuth: true },
    // },
    // {
    //     path: prefix + "services/help/ouvidoria",
    //     name: "HelpOuvidoria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Carrinho" */ "@/views/help/HelpOmbudsman.vue"
    //         ),
    //     meta: { title: TITTLE + "Help", requiresAuth: true },
    // },
    
    //SPECIAIS
    {
        path: prefix + "design-system",
        name: "DesignSystem",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "DesignSystem" */ "@/views/DesignSystem.vue"
            ),
        meta: { title: TITTLE + "Design System", requiresAuth: true },
    },
    {
        path: prefix + "benefits",
        name: "Benefits",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Webmail" */ "@/views/Benefits.vue"),
        meta: { title: TITTLE + "Benefits", requiresAuth: true },
    },
    {
        path: prefix + "hubperfil/pedidos",
        name: "Update1",
        props: true,
        params: true,
        redirect: prefix + "update",
    },
    {
        path: prefix + "landpage/:pagina",
        name: "LandPage",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Webmail" */ "@/views/Landingpage.vue"),
        meta: { title: TITTLE + "Landpage", requiresAuth: true },
    },
    {
        path: prefix + "voucher",
        name: "Voucher",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Webmail" */ "@/views/Voucher.vue"),
        meta: { title: TITTLE + "Voucher", requiresAuth: true },
    },

    {
        path: prefix + "facialManagement",
        name: "GestaoFacial",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "home" */ "@/views/Facial/gestaoFacial.vue"),
        meta: { title: TITTLE + "Facial Management", requiresAuth: true, breadcrumb: [
            { name: "Início", link: "Hub" },
            { name: "Gestão Facial" },
        ],},
    },

    // Institucionais
    // {
    //     path: prefix + "/",
    //     name: "HomeInstitucional",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(/* webpackChunkName: "BeiraRio" */ "@/views/Home.vue"),
    //     meta: { title: TITTLE + "HomeInstitucional", requiresAuth: false },
    // },

    //NOTICIAS
    // {
    //     path: prefix + "news",
    //     name: "Noticias",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/news/News.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Noticias",
    //         requiresAuth: false,
    //         breadcrumb: [{ name: "Home", link: "Home" }, { name: "News" }],
    //     },
    // },
    // {
    //     path: prefix + "news/:category",
    //     name: "NoticiasCategoria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/news/NewsCategory.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "News-category",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "News", link: "Noticias" },
    //             { name: "Category" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "new/:category/:subtitle",
    //     name: "NoticiasSubtitulo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/news/NewsSubtitle.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "New-title",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "News", link: "Noticias" },
    //             { name: "Category", link: "NoticiasCategoria" },
    //             { name: "New" },
    //         ],
    //     },
    // },

    // {
    //     path: prefix + "galery",
    //     name: "Galeria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/galery/PhotoGalery.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Galery-photos",
    //         requiresAuth: false,
    //         breadcrumb: [{ name: "Home", link: "Home" }, { name: "Gallery" }],
    //     },
    // },
    // {
    //     path: prefix + "galery/:category",
    //     name: "GaleriaCategoria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/galery/PhotoCategory.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Galery-category",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Gallery", link: "Galeria" },
    //             { name: "Category" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "galery/:category/:subtitle",
    //     name: "GaleriaGrupo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/galery/PhotoGroup.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Galery-group",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Galeries", link: "Galeria" },
    //             { name: "Category", link: "GaleriaCategoria" },
    //             { name: "Galery" },
    //         ],
    //     },
    // },

    //PROJETOS
    // {
    //     path: prefix + "projects-colorados",
    //     name: "ProjetosColorados",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/projetos-colorados/Projects.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Colorado's Projects",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Colorado's Projects" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "projects-colorados/:category",
    //     name: "ProjetosColoradosCategoria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/projetos-colorados/ProjectsCategory.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "projects",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Projects Colorados", link: "ProjetosColorados" },
    //             { name: "Project" },
    //         ],
    //     },
    // },

    //FUTEBOL
    // {
    //     path: prefix + "football",
    //     name: "Futebol",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/football/FootBall.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "football",
    //         requiresAuth: false,
    //         breadcrumb: [{ name: "Home", link: "Home" }, { name: "FootBall" }],
    //     },
    // },
    // {
    //     path: prefix + "football/:category",
    //     name: "FutebolCategoria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/football/FootballCategory.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "futebol-categoria",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "FootBall", link: "Futebol" },
    //             { name: "Category" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "football/:equip/:category",
    //     name: "FutebolEquipe",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/football/FootballEquip.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "football-equip",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "FootBall", link: "Futebol" },
    //             {
    //                 name: "Category",
    //                 link: "FutebolCategoria",
    //                 FutebolCategoria: true,
    //             },
    //             { name: "Equip" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "football/category/equip/player/:player",
    //     name: "EquipPlayer",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/football/EquipPlayer.vue"
    //         ),
    //     meta: { title: TITTLE + "equip-player", requiresAuth: false },
    // },

    //Clube
    // {
    //     path: prefix + "club",
    //     name: "Clube",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/club/Club.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "club",
    //         requiresAuth: false,
    //         breadcrumb: [{ name: "Home", link: "Home" }, { name: "Club" }],
    //     },
    // },
    // // Titulos
    // {
    //     path: prefix + "club/titles",
    //     name: "HistoriaTitulos",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/titles/Titulos.vue"
    //         ),
    //     meta: { title: TITTLE + "Titles", requiresAuth: false },
    // },
    // {
    //     path: prefix + "club/titles/:slug",
    //     name: "ClubTitle",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/titles/ClubTitle.vue"
    //         ),
    //     meta: { title: TITTLE + "Titles", requiresAuth: false },
    // },
    // // história do clube
    // {
    //     path: prefix + "club/history",
    //     name: "Historia",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/club/HistoryClub.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "history-club",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Club", link: "Clube" },
    //             { name: "History-Begging" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "club/idols",
    //     name: "Idolos",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/club/Idols.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "idols",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Club", link: "Clube" },
    //             { name: "Idols" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "club/idol/:player",
    //     name: "IdolPlayer",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/club/Idol-Player.vue"
    //         ),
    //     meta: { title: TITTLE + "Idol Player", requiresAuth: false },
    // },
    // // Simbolos
    // {
    //     path: prefix + "club/symbols",
    //     name: "Simbolos",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Simbolos" */ "@/views/institucionais/simbols/Home.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Colorado Symbols",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Club", link: "Clube" },
    //             { name: "Symbols" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "club/symbols/saci",
    //     name: "SimbolosSaci",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "SimbolosSaci" */ "@/views/institucionais/simbols/Saci.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Colorado Symbols - Saci",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "club/symbols/saci/:slug",
    //     name: "SaciSlide",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "SimbolosSaciNascimento" */ "@/views/institucionais/simbols/SaciSlide.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Colorado Symbols - Saci",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "club/symbols/badge",
    //     name: "SimbolosDistintivo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "SimbolosDistintivo" */ "@/views/institucionais/simbols/Distintivo.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Colorado Symbols - Badge",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "club/symbols/badge/:slug",
    //     name: "SimbolosDistintivoSlide",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "SimbolosDistintivo1920" */ "@/views/institucionais/simbols/Distintivo-slide.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Colorado Symbols - Badge",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "club/symbols/flag",
    //     name: "SimbolosBandeira",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "SimbolosBandeira" */ "@/views/institucionais/simbols/Bandeira.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Colorado Symbols - Flag",
    //         requiresAuth: false,
    //     },
    // },

    // {
    //     path: prefix + "club/grenais-list",
    //     name: "ListaGrenais",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Grenais-List.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "grenais-list",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Club", link: "Clube" },
    //             { name: "List of Grenais" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "club/cipher-hymn",
    //     name: "CifraHino",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Cifra-hino.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "cipher-hymn",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Club", link: "Clube" },
    //             { name: "Hymn cipher" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "club/functions-consulate",
    //     name: "FuncoesConsulado",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Funcoes-consulado.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "functions-consulate",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Club", link: "Clube" },
    //             { name: "Functions of the Consulate" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "club/giant-banners",
    //     name: "FaixaGigante",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Faixa-gigante.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Giant Banners",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Club", link: "Clube" },
    //             { name: "Banner in Gigante" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "club/culturais-consuls",
    //     name: "ConsulesCulturais",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Consules-culturais.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "culturais-consuls",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Club", link: "Clube" },
    //             { name: "Cultural Consuls" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "club/feci",
    //     name: "Feci",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Feci.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "feci",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Club", link: "Clube" },
    //             { name: "FECI" },
    //         ],
    //     },
    // },

    //   BEIRA-RIO
    // {
    //     path: prefix + "beira-rio",
    //     name: "BeiraRio",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/beira-rio/Beira-rio.vue"
    //         ),
    //     meta: { title: TITTLE + "Beira-Rio", requiresAuth: false },
    // },
    // {
    //     path: prefix + "beira-rio/:slug",
    //     name: "BeiraRioSlide",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/beira-rio/BeiraRioSlides.vue"
    //         ),
    //     meta: { title: TITTLE + "Beira-Rio", requiresAuth: false },
    // },

    // DIRECTORS
    // {
    //     path: prefix + "directors",
    //     name: "Directors",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Directors" */ "@/views/institucionais/directors/BoardDirectors.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Directors",
    //         requiresAuth: false,
    //         breadcrumb: [{ name: "Home", link: "Home" }, { name: "Directors" }],
    //     },
    // },
    // {
    //     path: prefix + "directors/members",
    //     name: "DiretoriaMembros",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/directors/DirectorMembers.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Board members",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Directors", link: "Diretoria" },
    //             { name: "Members" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "directors/fiscal-council",
    //     name: "ConselhoFiscal",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/directors/FiscalCouncil.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Fiscal Council",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Directors", link: "Diretoria" },
    //             { name: "Fiscal Council" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "directors/council-management",
    //     name: "ConselhoGestao",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/directors/ManagementCouncil.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Council Management",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Directors", link: "Diretoria" },
    //             { name: "Council Management" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "directors/council-deliberative",
    //     name: "ConselhoDeliberativo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/directors/DeliberativeCouncil.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Concuil Deliberative",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Directors", link: "Diretoria" },
    //             { name: "Concuil Deliberative" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "directors/former-presidents",
    //     name: "ExPresidentes",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/directors/ExPresidentes.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Former Presidents",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Directors", link: "Diretoria" },
    //             { name: "Former Presidents" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "directors/statute-club",
    //     name: "EstatutoClube",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/directors/StatuteClub.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Club Statute",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Directors", link: "Diretoria" },
    //             { name: "Club Statute" },
    //         ],
    //     },
    // },

    //PARQEU GIGANTE
    // {
    //     path: prefix + "services",
    //     name: "Servicos",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/Servicos.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Services",
    //         requiresAuth: false,
    //         breadcrumb: [{ name: "Home", link: "Home" }, { name: "Serices" }],
    //     },
    // },
    // {
    //     path: prefix + "services/giantpark",
    //     name: "ParqueGigante",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //      /* webpackChunkName: "ParqueGigante" */ "@/views/ParqueGigante/ParqueGigante.vue"
    //    ),
    //     meta: {
    //         title: TITTLE + "Giant Park",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Services", link: "Servicos" },
    //             { name: "Park" },
    //         ],
    //         parque: true,
    //     },
    // },
    // {
    //     path: prefix + "services/giantpark/:produto",
    //     name: "ProdutosParque",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //      /* webpackChunkName: "ParqueGigante" */ "@/views/ParqueGigante/Produtos-Parque.vue"
    //    ),
    //     meta: {
    //         title: TITTLE + "ballroom",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Services", link: "Servicos" },
    //             { name: "Park", link: "ParqueGigante" },
    //             { name: "Products" },
    //         ],
    //         parque: true,
    //     },
    // },
    // {
    //     path: prefix + "services/giantpark/products/parkplans",
    //     name: "PlanosParque",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //      /* webpackChunkName: "ParqueGigante" */ "@/views/ParqueGigante/Plans-park.vue"
    //    ),
    //     meta: {
    //         title: TITTLE + "Park Plans",
    //         requiresAuth: true,
    //         parque: true,
    //     },
    // },

    // {
    //     path: prefix + "services/giantpark/products/reserve-park",
    //     name: "ReservarPark",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "ParqueGigante" */ "@/views/ParqueGigante/Reservar-park.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "reserve",
    //         requiresAuth: true,
    //     },
    // },
    // {
    //     path: prefix + "services/giantpark/products/reserve-park/confirm",
    //     name: "ReservarConcluir",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "ParqueGigante" */ "@/views/ParqueGigante/Reservar-pagamento.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "reserve-confirm",
    //         requiresAuth: true,
    //     },
    // },

    // MUSEU DO INTER
    // {
    //     path: prefix + "services/inter-museum",
    //     name: "MuseuDoInter",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "MuseuDoInter" */ "@/views/museu-do-inter/Home.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Inter Museum",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Services", link: "Servicos" }, 
    //             { name: "Inter Museum" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "services/visit-colorada",
    //     name: "VisitaColorada",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "MuseuDoInter" */ "@/views/museu-do-inter/Visita-Colorada.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Visit Colorada",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "services/visit-express",
    //     name: "VisitaExpress",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "MuseuDoInter" */ "@/views/museu-do-inter/Visita-Express.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Visit Express",
    //         requiresAuth: false,
    //     },
    // },

    // ESTACIONAMENTO
    // {
    //     path: prefix + "services/parking",
    //     name: "Estacionamento",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Estacionamento" */ "@/views/Estacionamento.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Parking",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Services", link: "Servicos" },  
    //             { name: "Parking" },
    //         ],
    //     },
    // },

    // GAMIFICATION
    // {
    //     path: prefix + "experiences",
    //     name: "Gamification",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "Diretoria" */ "@/views/gamification/Gamification.vue"
    //    ),
    //     meta: {
    //         title: TITTLE + "Experiences",
    //         requiresAuth: true,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Experience" },
    //         ],
    //         gamification: true,
    //     },
    // },
    // {
    //     path: prefix + "experiences/list",
    //     name: "Experiences",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "Diretoria" */ "@/views/gamification/Experiences.vue"
    //    ),
    //     meta: {
    //         title: TITTLE + "Experiences - Experiences list",
    //         requiresAuth: true,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Experience", link: "Gamification" },
    //             { name: "Catalogue" },
    //         ],
    //         gamification: true,
    //     },
    // },
    // {
    //     path: prefix + "experiences/earn-pontos",
    //     name: "EarnPoints",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "Diretoria" */ "@/views/gamification/Earn-points.vue"
    //    ),
    //     meta: {
    //         title: TITTLE + "Experiences - Earn points",
    //         requiresAuth: true,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Experience", link: "Gamification" },
    //             { name: "Earn points" },
    //         ],
    //         gamification: true,
    //     },
    // },
    // {
    //     path: prefix + "experiences/history",
    //     name: "History",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "Diretoria" */ "@/views/gamification/History.vue"
    //     ),
    //     meta: {
    //         title: TITTLE + "Experiences - History",
    //         requiresAuth: true,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Experience", link: "Gamification" },
    //             { name: "Historic" },
    //         ],
    //         gamification: true,
    //     },
    // },
    // {
    //     path: prefix + "experiences/rubros",
    //     name: "Rubros",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "Diretoria" */ "@/views/gamification/Rubros.vue"
    //     ),
    //     meta: {
    //         title: TITTLE + "Experience - Rubros",
    //         requiresAuth: true,
    //         breadcrumb: [
    //             { name: "Home", link: "Home" },
    //             { name: "Experience", link: "Gamification" },
    //             { name: "Rubros" },
    //         ],
    //         gamification: true,
    //     },
    // },

    //ADMIN CADASTRO DE CONTEÚDOS
    {
        path: prefix + "we/CMS/registration/content",
        name: "adminRegister",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminView.vue"
            ),
        meta: {
            title: TITTLE + "Content Registration",
            requiresAuth: false,
        },
    },
    // {
    //     path: prefix + "we/CMS/registration/content/live",
    //     name: "adminLive",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminLive.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Live Video Registration",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/transparencia",
    //     name: "adminTransparencia",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminTransparencia.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Portal da transparência",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registration/content/news",
    //     name: "adminNoticias",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminNews.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "News Admin",
    //         requiresAuth: false,
    //     },
    // },
    {
        path: prefix + "we/CMS/registration/content/banners",
        name: "adminBanners",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminBanners.vue"
            ),
        meta: {
            title: TITTLE + "Banners Admin",
            requiresAuth: false,
        },
    },
    // {
    //     path: prefix + "we/CMS/registration/content/gallery",
    //     name: "adminGaleria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminGallerys.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Gallery Admin",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registration/content/football",
    //     name: "adminFutebol",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminFootball.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Football Admin",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registration/content/idolos",
    //     name: "adminIdolos",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminIdols.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin de Ídolos",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registration/content/projetos-colorados",
    //     name: "adminProjeto",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminProjetos.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin de Projetos Colorados",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registration/content/beirario",
    //     name: "adminBeiraRio",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminBeiraRio.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin do Beira Rio",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registration/content/distintivos",
    //     name: "adminDistintivo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminDistintivo.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin de Distintivos",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registration/content/bandeira",
    //     name: "adminBandeira",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminBandeira.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin da Bandeira",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registration/content/historia",
    //     name: "adminHistory",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminHistory.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin da História",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registration/content/saci",
    //     name: "adminSaci",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminSaci.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin do Saci",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registration/content/diretoria",
    //     name: "adminBoardDirectors",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminBoardDirectors.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin da Diretoria",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registration/content/mais-sobre-clube",
    //     name: "adminMoreAbout",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminMoreAbout.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin da Mais sobre o clube",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registration/content/lista-de-grenais",
    //     name: "adminGrenalList",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminGrenalList.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin lista de grenais",
    //         requiresAuth: false,
    //     },
    // },
    {
        path: prefix + "we/CMS/registration/content/game-service",
        name: "adminServicoJogo",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminServicoJogo.vue"
            ),
        meta: {
            title: TITTLE + "Game Service Admin",
            requiresAuth: false,
        },
    },
    {
        path: prefix + "we/CMS/registration/content/routes",
        name: "adminRotas",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "adminRotas" */ "@/views/admin/AdminRotas.vue"
            ),
        meta: {
            title: TITTLE + "Route admin",
            requiresAuth: false,
        },
    },
    // {
    //     path: prefix + "we/CMS/registration/content/park",
    //     name: "adminParque",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "adminparque" */ "@/views/admin/AdminParque.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Park Admin",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/registration/content/museum",
    //     name: "adminMuseu",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "adminMuseu" */ "@/views/admin/AdminMuseu.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Museum Admin",
    //         requiresAuth: false,
    //     },
    // },

    // PORTAL DA TRANSPARÊNCIA
    // {
    //     path: prefix + "transparency-portal",
    //     name: "portalTransparencia",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Carrinho" */ "@/views/transparencia/Portal-transparencia-home.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Transparency Portal",
    //         requiresAuth: false,
    //     },
    // },
    // // TRANSPARENCIA
    // { 
    //     path:
    //         prefix +
    //         "transparency-portal/informational-financial/:sub_menu",
    //     name: "TransparenciaSubMenu",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/transparencia/Transparencia.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Transparency Portal",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "transparency-portal/institutional/:sub_menu",
    //     name: "TransparenciaInstitucional",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/transparencia/Transparencia.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Transparency Portal",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "transparency-portal/management-programs/:sub_menu",
    //     name: "TransparenciaGestao",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/transparencia/Transparencia.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Transparency Portal",
    //         requiresAuth: false,
    //     },
    // },

    {
        path: prefix + "detalhes-app",
        name: "DetalhesApp",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "BeiraRio" */ "@/views/App/Detalhes.vue"
            ),
        meta: {
            title: TITTLE + "Detalhes do App",
            requiresAuth: false,
        },
    },

    ...linksOlds,
];
